import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Card1, Card3, Card7, Card9 } from './cards';
import { isPLPRequiredSystem } from '@/utils/isPLPRequiredSystem';
import { getRoute, PATH } from '@/utils/routes';
import { deviceWidth } from '@/utils/deviceWidth';
import { useSSRSelector } from '@/redux/ssrStore';

const CategoryWidget = (props) => {
  const { showSeeMoreBtn = true } = props;
  const router = useRouter();
  const [widgetConfig, setWidgetConfig] = useState(null);

  /**
   * calculate the max number of items that are to be
   * rendered initially when page initialises
   * result depends on deviceWidth
   * Sample config structure
   * {
    "animation": "pop",
    "aspect_ratio": 100,
    "card_cta_visibility": false,
    "desktop": {
        "grid": {
            "cols": 2,
            "rows": 2
        },
        "spacing": 24
    },
    "heading": "Categories",
    "mobile": {
        "grid": {
            "cols": 1,
            "rows": 2
        },
        "spacing": 24
    },
    "spacing": 24
    }
   * @return {number} number of items
   */
  const getMaxItemsToBeRenderedInitially = () => {
    let gridConfig = {};
    if (deviceWidth < 766) {
      gridConfig = props.config?.mobile;
    } else {
      gridConfig = props.config?.desktop;
    }
    if (gridConfig?.grid?.cols === 1) {
      return 4;
    } else {
      return gridConfig?.grid?.cols * 2;
    }
  };

  const [lastCategoryVisibleIndex, setLastCategoryVisibleIndex] = useState(
    getMaxItemsToBeRenderedInitially()
  );

  const { catalogCategories, storeData } = useSSRSelector((state) => ({
    catalogCategories: state.catalogReducer.categories,
    storeData: state.storeReducer.store,
  }));

  const toggleCategoryExpansion = () => {
    if (catalogCategories?.length > lastCategoryVisibleIndex) {
      setLastCategoryVisibleIndex(catalogCategories.length || 0);
    } else {
      setLastCategoryVisibleIndex(getMaxItemsToBeRenderedInitially());
    }
  };

  /**
   * showSeeMoreBtn comes in as prop and is used to conditionally render
   * the max amount of category items. In /categories page, there is no max limit on
   * the items that can be rendered at a time.
   * showSeeMoreBtn is false for /categories page
   */
  useEffect(() => {
    if (props.config) {
      setWidgetConfig(props.config);
      setLastCategoryVisibleIndex(
        showSeeMoreBtn ? getMaxItemsToBeRenderedInitially() : catalogCategories?.length
      );
    }
  }, [props.config, catalogCategories]);

  /**
   * conditionally render show more/less btn.
   * for button to render num of categories should be more than
   * max items to be rendered before button toggle
   * @return {boolean} boolean
   */
  const renderShowBtn = () =>
    showSeeMoreBtn && catalogCategories?.length > getMaxItemsToBeRenderedInitially();

  const onCategoryItemClick = (category) => {
    if (isPLPRequiredSystem(storeData?.theme?.theme_class)) {
      router.push(
        getRoute(`${PATH.SHOP}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        getRoute(`${PATH.PRODUCT}?cid=${category.id}`, storeData?.store_info?.domain),
        undefined,
        { shallow: true }
      );
    }
  };

  const _renderCategoryCard = (category, idx, cardNumber) => {
    switch (cardNumber) {
      case 1:
        return (
          <Card1
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
      case 3:
        return (
          <Card3
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
      case 7:
        return (
          <Card7
            category={category}
            cardIndx={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
            skewDirection={
              Math.ceil(
                (idx + 1) /
                  (deviceWidth > 990 ? widgetConfig?.desktop?.grid?.cols || 3 : 1)
              ) %
                2 !==
              0
                ? 'left'
                : 'right'
            }
          />
        );
      case 9:
        return (
          <Card9
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
      default:
        return (
          <Card1
            category={category}
            key={idx}
            widgetConfig={widgetConfig}
            onClick={onCategoryItemClick}
          />
        );
    }
  };

  /**
   * returns the number of columns columns from
   * props.config based on the device width
   * @return {number} no of columns
   */
  const returnGridTemplateColumns = () => {
    return deviceWidth < 766
      ? widgetConfig?.mobile?.grid?.cols || 1
      : widgetConfig?.desktop?.grid?.cols || 3;
  };

  /**
   * returns the grid gap value based on
   * deviceWidth
   * @return {number} grid gap value
   */
  const returnGridSpacing = () => {
    return deviceWidth < 766
      ? widgetConfig?.mobile?.spacing || 20
      : widgetConfig?.desktop?.spacing || 20;
  };

  return (
    <div className="vertical-categoryWidget flex justify-center items-center">
      {widgetConfig && (
        <div className="vertical-categoryWidget__container">
          <p className="vertical-categoryWidget__title tc fw6">{widgetConfig?.heading}</p>
          <section
            className="vertical-categoryWidget__box"
            style={{
              gridTemplateColumns: `repeat(${returnGridTemplateColumns()}, 1fr)`,
              gridGap: `${returnGridSpacing()}px`,
            }}
          >
            {catalogCategories
              ?.slice(0, lastCategoryVisibleIndex)
              .map((category, index) => {
                return _renderCategoryCard(category, index, props.cardType);
              })}
          </section>
          {renderShowBtn() && (
            <section className="see-more-btn flex justify-center items-center">
              <button onClick={toggleCategoryExpansion} className="expandable-btn">
                <span className="w-100 tc">
                  See{' '}
                  {catalogCategories?.length > lastCategoryVisibleIndex ? 'More' : 'Less'}
                </span>
              </button>
            </section>
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryWidget;
